import React from "react";
import CursorProvider from "context/CursorContext";
import Cursor from "components/Cursor";
import "styles/globals.css";
import Script from "next/script";
import { domAnimation, LazyMotion } from "framer-motion";

export default function App({ Component, pageProps }) {
  return (
    <LazyMotion features={domAnimation}>
      <CursorProvider>
        <Script
          id="facebook-pixel"
          dangerouslySetInnerHTML={{
            __html: `
              function launchFB() {
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', 202558192429980);
                fbq('track', 'PageView');
              }
            `,
          }}
          lazyOnload
        />

        {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && (
          <>
            <Script
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`}
              lazyOnload
            />
            <Script
              id="google-analytics"
              dangerouslySetInnerHTML={{
                __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){ dataLayer.push(arguments); }
                    gtag('config', "${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}");
                    gtag('js', new Date());
                    gtag("consent", "default", {
                      ad_storage: "denied",
                      analytics_storage: "denied"
                    });
                  `,
              }}
              lazyOnload
            />
          </>
        )}

        {process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID && (
          <>
            <Script
              id="axeptio-injector"
              dangerouslySetInnerHTML={{
                __html: `
                if (typeof window.axeptioSettings === "undefined") {
                  window.axeptioSettings = {
                    clientId: "${process.env.NEXT_PUBLIC_AXEPTIO_CLIENT_ID}",
                    cookiesVersion: "${process.env.NEXT_PUBLIC_AXEPTIO_COOKIES_VERSION}",
                  };
                  
                  (function(d, s) {
                    let t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                    e.async = true; e.src = "//static.axept.io/sdk-slim.js";
                    t.parentNode.insertBefore(e, t);
                  })(document, "script");
                }
              `,
              }}
            />

            <Script
              id="axeptio-process"
              dangerouslySetInnerHTML={{
                __html: `
                  window.adsbygoogle = window.adsbygoogle || [];
                  window.dataLayer = window.dataLayer || [];
                  window._axcb = window._axcb || [];
                  window.adsbygoogle.pauseAdRequests = 1;

                  window._axcb.push(function(axeptio) {
                    var consentSettings = {
                      ad_storage: "denied",
                      analytics_storage: "denied"
                    };
                    axeptio.on("cookies:complete", function(choices) {
                      if (choices.google_analytics) {
                        consentSettings.analytics_storage = "granted";
                        gtag("send", "pageview");
                      }

                      if (choices.google_ads) {
                        consentSettings.ad_storage = "granted";
                      } else {
                        gtag("set", "url_passthrough", true);
                        window.adsbygoogle.requestNonPersonalizedAds = 1;
                      }

                      if (choices.facebook_pixel) {
                        launchFB();
                      }

                      gtag("consent", "update", consentSettings);
                      window.adsbygoogle.pauseAdRequests = 0;
                    })
                  })
              `,
              }}
            />
          </>
        )}
        <Cursor />
        <Component {...pageProps} />
      </CursorProvider>
    </LazyMotion>
  );
}
